import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { SettingsDefaultKeysEnum, predictedBasketsCalculation } from '@memberapp/models'
import { TripType } from '../../models'
import { getSettings, queryKey } from '../../services/settingsSvc'

type TripsSummaryRowProps = {
  trips: TripType[]
  kgsPerBasket: number
}

const TripsSummaryRow: React.FC<TripsSummaryRowProps> = ({ trips, kgsPerBasket }) => {
  const { data: settings } = useQuery(queryKey, getSettings)

  const totalEstBaskets = (trips || []).reduce((total: number, obj: TripType) => total + obj.estimatedBaskets, 0)
  const totalEstWeight = (totalEstBaskets || 0) * (kgsPerBasket || 0)

  const totalActualBaskets = (trips || []).reduce((total: number, obj: TripType) => total + (obj.actualBaskets || 0), 0)
  const totalActualWeight = (totalActualBaskets || 0) * (kgsPerBasket || 0)

  const percentageList = useMemo(() => {
    return (
      settings
        ?.find((v) => v._id === SettingsDefaultKeysEnum.TRIP_PREDICTION_WEIGHTING_PERCENTAGE_LAST_3_TRIPS)
        ?.value?.split(',') || []
    )
  }, [settings])

  const filteredBaskets = trips.map((trip) => {
    const predictedBasket = predictedBasketsCalculation({ percentageList, trip })
    return predictedBasket.value
  })

  const totalPredictedBaskets = useMemo(() => {
    return filteredBaskets.reduce((total, predictedBaskest) => +(total || 0) + +(predictedBaskest || 0), 0)
  }, [filteredBaskets])

  const totalPredictedWeight = useMemo(() => {
    return parseFloat((((totalPredictedBaskets as number) || 0) * (kgsPerBasket || 0)).toFixed(2))
  }, [totalPredictedBaskets, kgsPerBasket])

  const totalRemainingBaskets = (trips || []).reduce(
    (total: number, obj: TripType) =>
      total + (obj.actualBaskets !== null && obj.actualBaskets >= 0 ? 0 : obj.estimatedBaskets),
    0
  )
  const totalRemainingWeight = useMemo(() => {
    return parseFloat((((totalRemainingBaskets as number) || 0) * (kgsPerBasket || 0)).toFixed(2))
  }, [totalRemainingBaskets, kgsPerBasket])

  if (!trips?.length) {
    return null
  }
  return (
    <tfoot>
      <tr>
        <td />
        <td />
        <td />
        <td />
        <td />
        <td className="trip-summary">
          {totalEstBaskets}
          <br />@ {kgsPerBasket} kgs <br />= {totalEstWeight} kgs
        </td>
        <td className="trip-summary">
          {totalPredictedBaskets}
          <br />@ {kgsPerBasket} kgs <br />= {totalPredictedWeight} kgs
        </td>
        <td className="trip-summary">
          {totalActualBaskets}
          <br />@ {kgsPerBasket} kgs <br />= {totalActualWeight} kgs
        </td>
        <td className="trip-summary">
          {totalRemainingBaskets}
          <br />@ {kgsPerBasket} kgs <br />= {totalRemainingWeight} kgs
        </td>
        <td />
        <td />
      </tr>
    </tfoot>
  )
}

export default TripsSummaryRow
