import React, { ChangeEvent, useCallback, useState } from 'react'

import { useQueryClient } from 'react-query'
import { Dict } from '@memberapp/models'
import Progress from '../../components/Progress'
import { addBait, getBait, editBait, deleteBait, enableBait, queryKey } from '../../services/baitSvc'
import useModal, { ShowModal } from '../../hooks/useModal'
import useQueryAndMutations, { QMProps } from '../../hooks/useQueryAndMutations'
import GenericAddEditModal, { ModalInputChangeEvent } from '../../components/modals/GenericAddEditModal'
import LabelWithError from '../../components/LabelWithError'
import { BaitType } from '../../models'
import FilterActiveDeleted from '../../components/FilterActiveDeleted'
import { Pencil } from '../../components/Icons'
import useDeleteEnable from '../../hooks/useDeleteEnable'
import SortButton from '../../components/SortButton'

const dflt: BaitType = {
  name: '',
  description: '',
} as BaitType

function validate(x: BaitType): Dict<string> {
  const errs: Dict<string> = {}
  if (!x.name) {
    errs['name'] = 'required'
  }
  if (!x.description) {
    errs['description'] = 'required'
  }
  return errs
}

const qmProps: QMProps<BaitType> = {
  queryKey: 'bait',
  listFn: getBait,
  addFn: addBait,
  editFn: editBait,
}

const BaitView: React.FC = () => {
  const queryClient = useQueryClient()
  const [warehouseFilter, setWarehouseFilter] = useState<string>('')
  const {
    data,
    addMutate,
    editMutate,
    reset: queryReset,
    error: queryError,
    isLoading: isQueryLoading,
    setQueryParams,
  } = useQueryAndMutations<BaitType>(qmProps)
  const { showModal, showAdd, showEdit, itemToEdit, handleClose } = useModal<BaitType>()
  const [queryParams, setViewQueryParams] = useState<Dict<unknown>>({ includeDeleted: false })
  const [sortAscending, setSortAscending] = useState<boolean>(true)

  const handleSetQueryParams = useCallback(
    (dict: Dict<unknown>) => {
      setViewQueryParams(dict)
      setQueryParams(dict)
    },
    [setViewQueryParams, setQueryParams]
  )

  const { ConfirmModals, DeleteEnableActions, isDeleteEnableLoading, deleteEnableError, deleteEnableReset } =
    useDeleteEnable<BaitType>({
      name: 'Bait',
      data,
      queryKey,
      filters: queryParams,
      deleteFunc: deleteBait,
      enableFunc: enableBait,
    })

  const isLoading = isQueryLoading || isDeleteEnableLoading

  const error = queryError || deleteEnableError

  const reset = useCallback(() => {
    if (queryClient) {
      deleteEnableReset()
      queryReset()
      queryClient.resetQueries()
    }
  }, [queryReset, queryClient, deleteEnableReset])

  let sortedData: BaitType[] = []
  if (data?.length) {
    if (sortAscending) {
      sortedData = data.sort((a: BaitType, b: BaitType) => a.name.localeCompare(b.name))
    } else {
      sortedData = data.sort((a: BaitType, b: BaitType) => b.name.localeCompare(a.name))
    }
    if (warehouseFilter) {
      if (warehouseFilter === 'ALL') {
        sortedData = sortedData.filter((f) => !f.warehouse)
      } else {
        const lowerFilter = warehouseFilter.toLowerCase()
        sortedData = sortedData.filter((f) => f.warehouse === lowerFilter)
      }
    }
  }

  const toggleSort = useCallback(() => {
    if (!setSortAscending) {
      return
    }
    setSortAscending(!sortAscending)
  }, [sortAscending, setSortAscending])

  const updateWarehouseFilter = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (warehouseFilter === value) {
      setWarehouseFilter('')
    } else {
      setWarehouseFilter(value)
    }
  }

  return (
    <div>
      <hgroup>
        <h2>Bait</h2>
        <h3>
          <a href="#" className="outline" onClick={showAdd}>
            Add New
          </a>
        </h3>
      </hgroup>
      {/* api state */}
      {isLoading && !error ? <Progress /> : []}
      {error ? <p onClick={() => reset()}>{(error as { message?: string })?.message}</p> : []}

      <table role="grid">
        <thead>
          <tr>
            <th>
              Name&nbsp;
              <SortButton {...{ sortAscending, toggleSort }} />
            </th>
            <th>Description</th>
            <th>
              <details className="dropdown" style={{ marginBottom: 0 }}>
                <summary
                  aria-haspopup="listbox"
                  style={{
                    border: 'none',
                    height: 'unset',
                    padding: 0,
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}>
                  Warehouse{warehouseFilter ? ` (${warehouseFilter})` : ''}
                </summary>
                <ul role="listbox">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        value="ALL"
                        checked={warehouseFilter === 'ALL'}
                        onChange={updateWarehouseFilter}
                      />
                      ALL
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        value="NORTH"
                        checked={warehouseFilter === 'NORTH'}
                        onChange={updateWarehouseFilter}
                      />
                      NORTH
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        value="SOUTH"
                        checked={warehouseFilter === 'SOUTH'}
                        onChange={updateWarehouseFilter}
                      />
                      SOUTH
                    </label>
                  </li>
                </ul>
              </details>
            </th>
            <th>
              <FilterActiveDeleted filters={queryParams} setQueryParams={handleSetQueryParams} />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((obj: BaitType) => (
            <tr key={obj._id}>
              <td>{obj.name}</td>
              <td>{obj.description}</td>
              <td style={{ textTransform: 'uppercase' }}>{obj.warehouse || 'all'}</td>
              <td>
                <a href="#" className="outline" onClick={() => showEdit(obj)}>
                  <Pencil />
                </a>
                {DeleteEnableActions(obj)}
              </td>
            </tr>
          ))}
          {!sortedData?.length ? (
            <tr>
              <td colSpan={3}>No results</td>
            </tr>
          ) : (
            []
          )}
        </tbody>
      </table>
      {showModal !== ShowModal.None ? (
        <GenericAddEditModal<BaitType>
          title={showModal === ShowModal.Add ? 'Add bait' : 'Edit bait'}
          queryKey="bait"
          handleClose={handleClose}
          mutate={showModal === ShowModal.Add ? addMutate : editMutate}
          dflt={dflt}
          validate={validate}
          item={itemToEdit}
          render={(obj: BaitType, errors: Dict<string>, handleChange: ModalInputChangeEvent) => (
            <>
              <LabelWithError {...{ name: 'name', errors }}>Name</LabelWithError>
              <input type="text" name="name" id="name" value={obj.name} required onChange={handleChange} />
              <LabelWithError {...{ name: 'description', errors }}>Description</LabelWithError>
              <input
                type="text"
                name="description"
                id="description"
                value={obj.description}
                required
                onChange={handleChange}
              />
              <LabelWithError {...{ name: 'warehouse', errors }}>Warehouse</LabelWithError>
              <select name="warehouse" value={obj.warehouse || ''} onChange={handleChange}>
                <option selected={!obj.warehouse} value="">
                  ALL
                </option>
                <option selected={obj.warehouse === 'north'} value="north">
                  NORTH
                </option>
                <option selected={obj.warehouse === 'south'} value="south">
                  SOUTH
                </option>
              </select>
            </>
          )}
        />
      ) : (
        []
      )}
      {ConfirmModals()}
    </div>
  )
}

export default BaitView
