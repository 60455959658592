import { parseTimeString, zonedStartOfDay } from '@memberapp/models'
import addHours from 'date-fns/addHours'
import addMinutes from 'date-fns/addMinutes'

export const buildZonedDateTime = (date: Date | string | number, time: string): Date => {
    const resultDate = zonedStartOfDay(date)
    const { hours, minutes } = parseTimeString(time)


    const finalDate = addMinutes(addHours(resultDate, hours), minutes)
    return finalDate
}