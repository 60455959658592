import { IPublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, Routes, useParams, useSearchParams } from 'react-router-dom'
import './App.scss'
import ThemeSwitcher from './components/ThemeSwitcher'
import { APP_LINK, BUILD_ID, ENV, VERSION } from './config'
import { AuthContainer } from './containers/AuthContainer'
import AppRoutes, { NavLinks } from './routes'

const now = new Date()
const queryClient = new QueryClient()

type AppProps = {
  pca: IPublicClientApplication
}

function AuthenticatedLayout() {
  return (
    <>
      <header>
        <h1>Member App Admin Portal</h1>
        {ENV !== 'PROD' ? <div className="env-badge">{ENV}</div> : ''}
      </header>
      <aside>
        <NavLinks />
      </aside>
      <div className="content">
        <AppRoutes />
      </div>
      <footer>
        <span>&#169; {now?.getFullYear()}</span>
        <span>
          v{VERSION}.{BUILD_ID} {ENV}
        </span>
      </footer>
      <ThemeSwitcher />
    </>
  )
}

function AppRedirectPage() {
  const { screen } = useParams()
  const [searchParams] = useSearchParams()

  const id = searchParams.has('id') ? searchParams.get('id') : undefined

  let url = `${APP_LINK}://${screen}`
  if (id) {
    url += `/${id}`
  }

  if (screen && searchParams) {
    window.location.href = url
  }
  return (
    <>
      <p style={{ color: 'black' }}>Redirecting to app...</p>
    </>
  )
}

function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <AuthContainer.Provider>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/app-redirect/:screen" element={<AppRedirectPage />} />
            <Route
              path="/*"
              element={
                <MsalProvider instance={pca}>
                  <AuthContainer.Provider>
                    <AuthenticatedLayout />
                  </AuthContainer.Provider>
                </MsalProvider>
              }
            />
          </Routes>
        </QueryClientProvider>
      </AuthContainer.Provider>
    </MsalProvider>
  )
}

export default App
