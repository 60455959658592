import React, { useCallback } from 'react'
import { Bait, BaitOrderDelivery, BaitOrderType as BaitOrderTypeEnum } from '@memberapp/models'
import { format, startOfTomorrow } from 'date-fns'
import { BaitOrderType, BaitType, EmptyUser, LocationType, UserType } from '../../models'
import LabelWithError from '../LabelWithError'

import Counter from '../Counter'

import './BaitOrderForm.css'
import { BaitOrderContainer } from '../../containers/BaitOrderContainer'
import LocationFilter from '../LocationsFilter'
import BoatFilter from '../BoatFilter'
import FavouritesDropdown from '../FavouritesDropdown'
import Progress from '../Progress'

type Props = {
  showTypeSelector?: boolean
  showBoatSelector?: boolean
  showFavouriteSelector?: boolean
  isEditMode?: boolean
  isTripBaitOrder?: boolean
}

const tomorrow = startOfTomorrow()

const filterBait = (bait: Bait[] = [], warehouse = ''): Bait[] => {
  if (!warehouse) {
    return []
  }
  return bait.filter((b) => !b.warehouse || b.warehouse === warehouse)
}

const BaitOrderForm: React.FC<Props> = ({
  showTypeSelector,
  showBoatSelector,
  showFavouriteSelector,
  isEditMode,
  isTripBaitOrder = false,
}) => {
  const {
    baitOrder,
    errors,
    bait,
    isLoadingBait,
    handleChange,
    setBaitOrder,
    baitCounts,
    updateBaitCounts,
    userFavouriteBaitOrders,
    isFavBaitOrdersLoading,
    selectedFavBaitOrder,
    handleSelectedFavBaitOrder,
  } = BaitOrderContainer.useContainer()

  const handleLocationSelected = useCallback(
    (val: LocationType | null) => {
      if (!setBaitOrder) {
        return
      }
      setBaitOrder({ ...baitOrder, collectionLocation: val } as BaitOrderType)
    },
    [baitOrder, setBaitOrder]
  )

  const handleBoatSelected = useCallback(
    (val: UserType | null) => {
      if (!setBaitOrder) {
        return
      }
      setBaitOrder({ ...baitOrder, user: val || EmptyUser } as BaitOrderType)
    },
    [setBaitOrder, baitOrder]
  )

  const filteredBait = filterBait(bait, baitOrder?.collectionLocation?.grading || '').sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )

  const isDefinitelyTripBaitOrder = !!(isTripBaitOrder || baitOrder?.type === BaitOrderTypeEnum.Trip)
  const isAFavouriteBaitOrder = !!(baitOrder?.type === BaitOrderTypeEnum.Favourite)
  const showCollectionDate =
    baitOrder?.type === BaitOrderTypeEnum.Scheduled || baitOrder?.deliveryType === BaitOrderDelivery.Scheduled

  return baitOrder ? (
    <>
      {showBoatSelector ? (
        <>
          <LabelWithError {...{ name: 'user', errors }}>Select Boat</LabelWithError>
          <BoatFilter value={(baitOrder.user as UserType)?._id || ''} onChange={handleBoatSelected} />
        </>
      ) : (
        <>
          <LabelWithError {...{ name: 'user', errors }}>Boat</LabelWithError>
          <label>{baitOrder.user?.boatName}</label>
        </>
      )}
      {showFavouriteSelector ? (
        <>
          <label>Select Favourite?</label>
          {isFavBaitOrdersLoading ? (
            <Progress />
          ) : (
            <FavouritesDropdown
              value={selectedFavBaitOrder}
              onChange={(e) => handleSelectedFavBaitOrder(e as BaitOrderType)}
              list={userFavouriteBaitOrders || []}
            />
          )}
        </>
      ) : (
        []
      )}
      {showTypeSelector ? (
        <>
          <LabelWithError {...{ name: 'type', errors }}>Order Type</LabelWithError>
          <select id="type" name="type" value={baitOrder.type} onChange={handleChange}>
            <option value={BaitOrderTypeEnum.Favourite}>{BaitOrderTypeEnum.Favourite}</option>
            <option value={BaitOrderTypeEnum.Scheduled}>{BaitOrderTypeEnum.Scheduled}</option>
            {isEditMode ? <option value={BaitOrderTypeEnum.Trip}>{BaitOrderTypeEnum.Trip}</option> : []}
          </select>
        </>
      ) : (
        []
      )}
      {isDefinitelyTripBaitOrder && (
        <>
          <LabelWithError {...{ name: 'deliveryType', errors }}>Delivery Type</LabelWithError>
          <select id="deliveryType" name="deliveryType" value={baitOrder.deliveryType} onChange={handleChange}>
            <option value="" disabled={true}>
              Select delivery option
            </option>
            <option value={BaitOrderDelivery.WhenIDeliver}>{BaitOrderDelivery.WhenIDeliver}</option>
            <option value={BaitOrderDelivery.Tonight}>{BaitOrderDelivery.Tonight}</option>
            <option value={BaitOrderDelivery.Scheduled}>{BaitOrderDelivery.Scheduled}</option>
          </select>
        </>
      )}

      <LabelWithError {...{ name: 'collectionLocation', errors }}>Collection Depot</LabelWithError>
      <LocationFilter
        value={(baitOrder.collectionLocation as LocationType)?._id || ''}
        onChange={handleLocationSelected}
        placeholderText="Select Depot"
        showCarrierBoats={false}
      />
      {showCollectionDate && (
        <>
          <LabelWithError {...{ name: 'collectionDate', errors }}>Collection Date</LabelWithError>
          <input
            type="date"
            id="collectionDate"
            name="collectionDate"
            value={format(baitOrder.collectionDate || tomorrow, 'yyyy-MM-dd')}
            onChange={handleChange}
            min={format(tomorrow, 'yyyy-MM-dd')}
          />
        </>
      )}

      {isLoadingBait ? (
        <span>loading bait...</span>
      ) : filteredBait?.length ? (
        <span className="baitOrderItems">
          {filteredBait.map((bait: BaitType) => (
            <span key={`${bait.name}.${bait.description}`} className="baitOrderItem">
              <span>
                <label>
                  {bait.name}
                  {bait.warehouse ? ` - ${bait.warehouse.toUpperCase()}` : ' - ALL'}
                </label>
                <span>{bait.description}</span>
              </span>
              <Counter
                count={baitCounts[`${bait._id}`] || 0}
                setCount={(x: number) => updateBaitCounts(`${bait._id}`, x)}
              />
            </span>
          ))}
        </span>
      ) : (
        <>
          <span>no bait found</span>
          {!baitOrder.collectionLocation && <span>try setting a collection location</span>}
        </>
      )}

      {baitOrder.type !== BaitOrderTypeEnum.Scheduled ? (
        <>
          <label htmlFor="isFavourite">Save as Favourite?</label>
          <input
            id="isFavourite"
            name="isFavourite"
            type="checkbox"
            checked={baitOrder.type === BaitOrderTypeEnum.Favourite}
            onChange={(e) =>
              setBaitOrder &&
              setBaitOrder({
                ...baitOrder,
                type: e.target.checked
                  ? BaitOrderTypeEnum.Favourite
                  : showTypeSelector
                  ? BaitOrderTypeEnum.Scheduled
                  : BaitOrderTypeEnum.Trip,
              })
            }
          />
          {isAFavouriteBaitOrder ? (
            <>
              <LabelWithError {...{ name: 'name', errors }}>Name</LabelWithError>
              <input type="text" id="name" name="name" value={baitOrder?.name || ''} onChange={handleChange} />
            </>
          ) : (
            []
          )}
        </>
      ) : (
        []
      )}
      <LabelWithError className="notes-label" {...{ name: 'notes', errors }} style={{ alignItems: 'flex-start' }}>
        Notes
      </LabelWithError>
      <textarea rows={4} id="notes" name="notes" value={baitOrder?.notes || ''} onChange={handleChange} />
    </>
  ) : null
}

export default BaitOrderForm
