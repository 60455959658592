import { zonedStartOfDay } from '@memberapp/models'
import { useState } from 'react'
import { useQuery } from 'react-query'
import BasicSelect from '../../components/BasicSelect'
import DateFilter from '../../components/DateFilter'
import Progress from '../../components/Progress'
import { getLandingSchedules, getLocations } from '../../services/locationsSvc'
import './LandingSchedules.scss'

export default function LandingSchedules() {
  const [date, setDate] = useState<Date>(zonedStartOfDay(new Date()))
  const [location, setLocation] = useState<string>('')
  const { data: locations, isLoading: isLoadingLocations } = useQuery(['locations'], () => getLocations())
  const { data: schedulesByLocation, isLoading: isLoadingSchedule } = useQuery(['landingSchedules', date], () =>
    getLandingSchedules(date)
  )

  const isLoading = isLoadingLocations || isLoadingSchedule
  const activeLocations = locations?.filter((f) => f.deletedAt === null).sort((a, b) => a.name.localeCompare(b.name))

  const selectedLocationName = location || activeLocations?.[0]?.name || ''
  const scheduleItems = selectedLocationName ? schedulesByLocation?.[selectedLocationName] ?? [] : []

  return (
    <div id="landing-schedules">
      <hgroup>
        <h2>Landing Schedules</h2>
        <div>
          <label>Offload Date</label>
          <DateFilter value={date} onChange={(e: Date | null) => setDate(zonedStartOfDay(e ?? new Date()))} />
          <label>Location</label>
          <BasicSelect value={location || activeLocations?.[0]?.name || ''} onChange={setLocation}>
            {locations
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((l) => (
                <option key={l.name} value={l.name}>
                  {l.name}
                </option>
              ))}
          </BasicSelect>
        </div>
      </hgroup>

      {/* api state */}
      {isLoading ? <Progress /> : []}
      <table role="grid">
        <thead>
          <tr>
            <th>Boat</th>
            <th>Offload Time</th>
          </tr>
        </thead>
        <tbody>
          {scheduleItems?.length > 0
            ? scheduleItems
                .sort((a, b) => a.orderKey - b.orderKey)
                .map((s) => (
                  <tr key={s.boatName}>
                    <td>{s.boatName}</td>
                    <td>{s.offloadTime}</td>
                  </tr>
                ))
            : null}
        </tbody>
      </table>
    </div>
  )
}
