import React, { useCallback } from 'react'

import { ShowModal } from '../../hooks/useModal'
import { TripType } from '../../models'
import TripForm from '../forms/TripForm'
import { TripContainer } from '../../containers/TripContainer'

interface Props {
  onClose: (obj: TripType | null) => void
}

const TripModal: React.FC<Props> = ({ onClose }) => {
  const { modalType, tripSaveDisabled, handleSave } = TripContainer.useContainer()
  const cancel = useCallback(() => onClose && onClose(null), [onClose])
  const save = useCallback(async () => {
    if (handleSave) {
      try {
        await handleSave()
        onClose && onClose(null)
      } catch (err) {
        console.error(err)
      }
    }
  }, [handleSave, onClose])

  return (
    <dialog className="modal" open>
      <article>
        <header>
          <h3>
            {modalType === ShowModal.Edit ? 'Edit Trip' : 'Add Trip'}
            <a href="#close" aria-label="Close" className="close" onClick={cancel}></a>
          </h3>
        </header>
        <p>
          <TripForm />
        </p>
        <footer>
          <a className="small" onClick={cancel}>
            Cancel
          </a>
          <button onClick={save} aria-disabled={tripSaveDisabled} disabled={tripSaveDisabled} className="small">
            Save
          </button>
        </footer>
      </article>
    </dialog>
  )
}

export default TripModal
