import React, { ComponentPropsWithoutRef } from 'react'

type Props = {
  errors: { [id: string]: unknown }
  name: string
  className?: string
} & ComponentPropsWithoutRef<'label'>

function LabelWithError(props: Props) {
  const { errors, name, children, className, ...rest } = props
  const classes: string[] = []
  if (className) {
    classes.push(className)
  }
  if (errors?.[name]) {
    classes.push('error')
  }
  return (
    <label htmlFor={name} className={classes.join(' ')} {...rest}>
      {children}
    </label>
  )
}

export default LabelWithError
