import { Dict, Id, NotificationTopics, User } from '@memberapp/models'
import React, { SyntheticEvent, useState } from 'react'

type UserType = User & Id

type Props = {
  handleClose: () => void
  handleSave: (u: UserType) => void
  user: UserType
}

const toSettingsDict = (user: UserType) =>
  user.notificationSettings.reduce((dict: Dict<boolean>, setting: string) => {
    dict[setting] = true
    return dict
  }, {} as Dict<boolean>)

const nameForSetting = (setting: string) => {
  switch (setting) {
    case `${NotificationTopics.BeachPrice}`:
      return 'Beach Prices'
    case `${NotificationTopics.NewsEvent}`:
      return 'Events'
    case `${NotificationTopics.NewsMessage}`:
      return 'Messages'
    case `${NotificationTopics.ConfirmNewTrip}`:
      return 'Confirm New Trip'
    case `${NotificationTopics.ConfirmTripETA}`:
      return 'Confirm Trip ETA'
  }
}

const UserModal: React.FC<Props> = ({ handleClose, handleSave, user }) => {
  const [notificationSettings, setNotificationSettings] = useState<Dict<boolean>>(toSettingsDict(user))

  const cancel = () => handleClose && handleClose()
  const save = async () => {
    if (!handleSave) {
      return
    }
    const editSettings = Object.keys(notificationSettings).filter((key) => notificationSettings[key])
    handleSave({ ...user, notificationSettings: editSettings })
  }

  const onChange = (event: SyntheticEvent) => {
    if (!event.target) {
      return
    }
    const { name, checked } = event.target as HTMLInputElement
    setNotificationSettings((prev) => ({ ...prev, [name]: checked }))
  }

  return (
    <dialog className="modal" open>
      <article>
        <header>
          <h3>
            Edit {user?.name || 'User'} Notification Settings
            <a href="#close" aria-label="Close" className="close" onClick={cancel}></a>
          </h3>
        </header>
        <p>
          {Object.entries(NotificationTopics).map(([key, value]) =>
            isNaN(Number(key)) ? (
              <>
                <label htmlFor={value}>
                  <input
                    type="checkbox"
                    name={value}
                    id={value}
                    checked={notificationSettings[value]}
                    onChange={onChange}
                  />
                  {nameForSetting(value)}
                </label>
                <br />
              </>
            ) : null
          )}
        </p>
        <footer>
          <a className="small" onClick={cancel}>
            Cancel
          </a>
          <button onClick={save} className="small">
            Save
          </button>
        </footer>
      </article>
    </dialog>
  )
}

export default UserModal
