import { filterBaitOrderItemsForWarehouse } from '@memberapp/models'
import React from 'react'
import { useQuery } from 'react-query'
import { BaitOrderContainer } from '../../containers/BaitOrderContainer'
import { ShowModal } from '../../hooks/useModal'
import { BaitOrderType, BaitType } from '../../models/index'
import { queryKey as baitQueryKey, getBait } from '../../services/baitSvc'
import BaitOrderForm from '../forms/BaitOrderForm'

interface Props {
  onClose: (baitOrder: BaitOrderType | null) => void
  showTypeSelector: boolean
  showBoatSelector: boolean
  showFavouriteSelector: boolean
  isEditMode?: boolean
  isTripBaitOrder?: boolean
}

function filterBaitOrderItemsByLocation(baitOrder: BaitOrderType, bait: BaitType[] = []): BaitOrderType {
  const tmp: BaitOrderType = {
    ...baitOrder,
    items: filterBaitOrderItemsForWarehouse(baitOrder.items, bait, baitOrder.collectionLocation?.grading || ''),
  }
  return tmp
}

const BaitOrderModal: React.FC<Props> = ({
  onClose,
  showTypeSelector = false,
  showBoatSelector = true,
  showFavouriteSelector = true,
  isEditMode = false,
  isTripBaitOrder = false,
}) => {
  const { data: bait } = useQuery(baitQueryKey, () => getBait())
  const { baitOrder, modalType, errors } = BaitOrderContainer.useContainer()
  const formDisabled = Object.keys(errors).length > 0

  const cancel = () => onClose?.(null)

  const save = () => {
    if (baitOrder) {
      const tmpBaitOrder: BaitOrderType = filterBaitOrderItemsByLocation(baitOrder, bait)
      onClose(tmpBaitOrder)
    }
  }

  return (
    <dialog className="modal" open>
      <article>
        <header>
          <h3>
            {modalType === ShowModal.Edit ? 'Edit Bait Order' : 'Add Bait Order'}
            <a href="#close" aria-label="Close" className="close" onClick={cancel}></a>
          </h3>
        </header>
        <p>
          <BaitOrderForm
            showTypeSelector={showTypeSelector}
            showBoatSelector={showBoatSelector}
            showFavouriteSelector={showFavouriteSelector}
            isEditMode={isEditMode}
            isTripBaitOrder={isTripBaitOrder}
          />
        </p>
        <footer>
          <a className="small" onClick={cancel}>
            Cancel
          </a>
          <button onClick={save} aria-disabled={formDisabled} disabled={formDisabled} className="small">
            Save
          </button>
        </footer>
      </article>
    </dialog>
  )
}

export default BaitOrderModal
