import {
  BaitOrderDelivery,
  BaitOrderItem,
  BaitOrderType as BaitOrderTypeEnum,
  Dict,
  Id,
  MemberCategory,
  validateBaitOrder,
} from '@memberapp/models'
import { SyntheticEvent, useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { createContainer } from 'unstated-next'
import { ShowModal } from '../hooks/useModal'
import { BaitOrderType, BaitType, EmptyBaitOrder, UserType } from '../models'
import { getFavourites } from '../services/baitOrderSvc'
import { queryKey as baitQueryKey, getBait } from '../services/baitSvc'
import { getLocations, queryKey as locationQueryKey } from '../services/locationsSvc'
import { getUsers, queryKey as userQueryKey } from '../services/userSvc'

type BaitOrderContainerType = {
  obj: BaitOrderType | null
  modalType: ShowModal
}

const state: BaitOrderContainerType = {
  obj: EmptyBaitOrder,
  modalType: ShowModal.Add,
}

function useBaitOrder(initialState: BaitOrderContainerType = state) {
  const { obj, modalType } = initialState
  const [baitOrder, setBaitOrder] = useState(obj)
  const { data: users } = useQuery([userQueryKey, modalType], () =>
    getUsers({ includeDeleted: modalType === ShowModal.Edit })
  )
  const { data: locationOptions } = useQuery(locationQueryKey, () => getLocations())
  const { data: bait, isLoading: isLoadingBait } = useQuery(baitQueryKey, () => getBait())
  const baitDict: Dict<BaitType> = {}
  if (bait?.length) {
    bait.forEach((b) => (baitDict[`${b._id}`] = b))
  }

  const items = baitOrder?.items || []

  const baitCounts: Dict<number> = items.reduce(
    (dict, item: BaitOrderItem & Id) => ({ ...dict, [`${item._id}`]: item.quantity } as Dict<number>),
    {}
  )

  const userId = (baitOrder?.user as UserType)?._id || ''
  const { data: userFavouriteBaitOrders, isLoading: isFavBaitOrdersLoading } = useQuery(['fav/baitOrder', userId], () =>
    getFavourites(userId)
  )

  const updateBaitCounts = useCallback(
    (id: string, val: number) => {
      if (!setBaitOrder) {
        return
      }

      const newItems: BaitOrderItem[] = [...items].filter(
        (f) => !f.warehouse || f.warehouse === baitOrder?.collectionLocation?.grading
      )
      const idx = newItems.findIndex((f: BaitOrderItem & Id) => f._id === id)
      if (val <= 0) {
        return setBaitOrder({
          ...baitOrder,
          items: newItems.filter((f: BaitOrderItem & Id) => f._id !== id),
        } as BaitOrderType)
      }
      if (idx >= 0) {
        newItems[idx].quantity = val
        return setBaitOrder({ ...baitOrder, items: newItems } as BaitOrderType)
      }
      const baitItem = baitDict[id]
      if (baitItem) {
        newItems.push({
          ...baitItem,
          quantity: val,
        })
        return setBaitOrder({ ...baitOrder, items: newItems } as BaitOrderType)
      }
      console.error('BaitOrderContainer.updateBaitCounts: bait not found for id', id)
    },
    [items, baitDict, baitOrder, setBaitOrder]
  )

  const userOptions = (users || []).filter((f: UserType) => f.memberCategory === MemberCategory.Skipper)

  const { landLocations, boatLocations } = {
    landLocations: (locationOptions || []).filter((f) => !f.isCarrierBoat),
    boatLocations: (locationOptions || []).filter((f) => f.isCarrierBoat),
  }

  const errors = baitOrder ? validateBaitOrder(baitOrder) : { baitOrder: 'bait order is null' }

  const baitOrderSaveDisabled = Object.keys(errors).length > 0

  const handleSelect = (evt: SyntheticEvent, list: Id[]) => {
    if (evt?.target) {
      const { name, value } = evt.target as HTMLInputElement
      const obj = list.find((f) => f._id === value)
      if (obj) {
        const newObj = { ...baitOrder, [name]: obj }
        setBaitOrder(newObj as BaitOrderType)
      }
    }
  }

  const handleUserChange = (evt: SyntheticEvent) => {
    handleSelect(evt, userOptions)
  }

  const handleLocationChange = (evt: SyntheticEvent) => {
    handleSelect(evt, locationOptions || [])
  }

  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      if (!event || !event.target || !setBaitOrder) {
        return
      }
      const { name, value, checked, type: inputType } = event?.target as HTMLInputElement
      let newVal: unknown = null
      switch (inputType) {
        case 'checkbox':
          newVal = checked
          break
        case 'date':
          newVal = new Date(value)
          break
        case 'number':
        case 'numeric':
          newVal = +value
          break
        default:
          newVal = value
      }
      let newObj = { ...baitOrder, [name]: newVal }
      if (name === 'deliveryType' && value === BaitOrderDelivery.Scheduled) {
        newObj = { ...newObj, ['type']: BaitOrderTypeEnum.Scheduled }
      }
      setBaitOrder(newObj as BaitOrderType)
    },
    [baitOrder, setBaitOrder]
  )

  const [selectedFavBaitOrder, setSelectedFavBaitOrder] = useState<BaitOrderType | null>(null)

  const handleSelectedFavBaitOrder = (favBaitOrder: BaitOrderType | null) => {
    setSelectedFavBaitOrder(favBaitOrder)
    if (favBaitOrder) {
      const newBaitOrder = { ...baitOrder, ...favBaitOrder, name: '' }
      newBaitOrder.type = BaitOrderTypeEnum.Trip
      newBaitOrder.collectionDate = baitOrder?.collectionDate || null
      delete newBaitOrder._id
      setBaitOrder(newBaitOrder as BaitOrderType)
    }
  }

  return {
    modalType,
    baitOrder,
    setBaitOrder,
    userOptions,
    landLocations,
    boatLocations,
    bait,
    isLoadingBait,
    errors,
    baitOrderSaveDisabled,
    handleUserChange,
    handleChange,
    baitDict,
    baitCounts,
    updateBaitCounts,
    handleLocationChange,
    userFavouriteBaitOrders,
    isFavBaitOrdersLoading,
    selectedFavBaitOrder,
    handleSelectedFavBaitOrder,
  }
}

export const BaitOrderContainer = createContainer(useBaitOrder)
