import { format, startOfTomorrow } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import { Dict, BaitOrderItem, BaitOrderType as BaitOrderTypeEnum } from '@memberapp/models'
import { useMutation, useQueryClient } from 'react-query'
import Progress from '../../components/Progress'
import { BaitOrderContainer } from '../../containers/BaitOrderContainer'
import useModal, { ShowModal } from '../../hooks/useModal'
import useQueryAndMutations, { QMProps } from '../../hooks/useQueryAndMutations'
import { BaitOrderType, EmptyBaitOrder } from '../../models'
import { addBaitOrder, editBaitOrder, getBaitOrders, queryKey, deleteBaitOrder } from '../../services/baitOrderSvc'
import BaitOrderModal from '../../components/modals/BaitOrderModal'
import { NotesMagnifyingGlass, Pencil, Trash } from '../../components/Icons'
import SortButton from '../../components/SortButton'
import ConfirmActionModal from '../../components/modals/ConfirmActionModal'
import FilterBar, { dfltState } from './FilterBar'

const qmProps: QMProps<BaitOrderType> = {
  queryKey,
  listFn: getBaitOrders,
  addFn: addBaitOrder,
  editFn: editBaitOrder,
  defaultArgs: dfltState,
}

const dflt: BaitOrderType = { ...EmptyBaitOrder, type: BaitOrderTypeEnum.Scheduled, collectionDate: startOfTomorrow() }

const BaitOrders: React.FC = () => {
  const queryClient = useQueryClient()
  const { data, reset, error, isLoading, setQueryParams, addMutate, editMutate } =
    useQueryAndMutations<BaitOrderType>(qmProps)

  const { showModal, showAdd, showEdit, handleClose, itemToEdit } = useModal<BaitOrderType>(dflt)
  const [queryParams, setViewQueryParams] = useState<Dict<unknown>>(dfltState)
  const [sortAscending, setSortAscending] = useState<boolean>(true)
  const [itemToDelete, setItemToDelete] = useState<BaitOrderType | null>(null)

  const {
    mutate: deleteMutation,
    isLoading: deleting,
    error: deleteError,
    reset: deleteReset,
  } = useMutation(deleteBaitOrder)

  const handleSetQueryParams = useCallback(
    (dict: Dict<unknown>) => {
      setViewQueryParams(dict)
      setQueryParams(dict)
    },
    [setViewQueryParams, setQueryParams]
  )

  const onModalClose = async (baitOrder: BaitOrderType | null) => {
    if (!baitOrder) {
      handleClose()
      return
    }
    const mutation = showModal === ShowModal.Add ? addMutate : editMutate
    if (!mutation) {
      return
    }
    if (showModal === ShowModal.Add) {
      delete baitOrder._id
    }
    await mutation(baitOrder, {
      onSuccess: (data: BaitOrderType) => {
        queryClient.setQueryData([queryKey, { id: data._id }], data)
        queryClient.invalidateQueries()
        handleClose()
      },
    })
  }

  const onDeleteConfirmed = async () => {
    if (itemToDelete?._id) {
      deleteMutation(itemToDelete._id, {
        onSuccess: () => {
          queryClient.setQueryData<BaitOrderType[]>(queryKey, (oldData) =>
            (oldData || []).filter((f) => f._id !== itemToDelete._id)
          )
          queryClient.invalidateQueries()
        },
        onSettled: () => {
          setItemToDelete(null)
        },
      })
    }
  }

  const sortedData = useMemo<BaitOrderType[]>(() => {
    if (!data?.length) {
      return []
    }
    if (sortAscending) {
      return data.sort((a: BaitOrderType, b: BaitOrderType) => a.user.boatName.localeCompare(b.user.boatName))
    }
    return data.sort((a: BaitOrderType, b: BaitOrderType) => b.user.boatName.localeCompare(a.user.boatName))
  }, [data, sortAscending])

  const toggleSort = useCallback(() => {
    if (!setSortAscending) {
      return
    }
    setSortAscending(!sortAscending)
  }, [sortAscending, setSortAscending])

  return (
    <div>
      <hgroup>
        <h2>Bait Orders</h2>
        <h3>
          <a href="#" className="outline" onClick={showAdd}>
            Add New
          </a>
        </h3>
      </hgroup>

      <FilterBar setQueryParams={handleSetQueryParams} filters={queryParams} />

      {/* api state */}
      {(isLoading && !error) || (deleting && !deleteError) ? <Progress /> : []}
      {!!error && (
        <p className="error-text" onClick={() => reset()}>
          {(error as { message?: string })?.message}
        </p>
      )}
      {!!deleteError && (
        <p className="error-text" onClick={deleteReset}>
          Delete failed: {(deleteError as { message?: string })?.message || 'Server Error'}
        </p>
      )}

      <table role="grid">
        <thead>
          <tr>
            <th>
              Boat&nbsp;
              <SortButton {...{ sortAscending, toggleSort }} />
            </th>
            <th>Collection Date</th>
            <th>Type</th>
            <th>Delivery?</th>
            <th>Fav Name?</th>
            <th>Location</th>
            <th>Items</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((obj: BaitOrderType) => {
            const sortedBaitItems =
              obj.items
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                .map((m: BaitOrderItem) => `${m.name} (${m.description}) x ${m.quantity}`)
                .join(', ') || ''
            return (
              <tr key={obj._id}>
                <td>{obj.user.boatName}</td>
                <td>{obj.collectionDate ? format(obj.collectionDate, 'd MMM yyyy') : ''}</td>
                <td>{obj.type}</td>
                <td>{obj.deliveryType}</td>
                <td>{obj.name || ''}</td>
                <td>
                  {obj.collectionLocation
                    ? [obj.collectionLocation.name, obj.collectionLocation.grading].filter(Boolean).join(' - ')
                    : ''}
                </td>
                <td>{sortedBaitItems}</td>
                <td>
                  <span style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <a href="#" className="outline" onClick={() => showEdit(obj)}>
                      <Pencil />
                    </a>
                    <a href="#" className="outline" onClick={() => setItemToDelete(obj)}>
                      <Trash />
                    </a>
                    {!!obj?.notes && (
                      <a href="#" className="outline" data-tooltip={obj.notes} data-placement="left">
                        <NotesMagnifyingGlass />
                      </a>
                    )}
                  </span>
                </td>
              </tr>
            )
          })}
          {!sortedData?.length ? (
            <tr>
              <td colSpan={8}>No results</td>
            </tr>
          ) : (
            []
          )}
        </tbody>
      </table>
      {showModal !== ShowModal.None ? (
        <BaitOrderContainer.Provider initialState={{ obj: itemToEdit, modalType: showModal }}>
          <BaitOrderModal
            onClose={onModalClose}
            showTypeSelector={true}
            showBoatSelector={true}
            showFavouriteSelector={false}
            isEditMode={showModal === ShowModal.Edit}
          />
        </BaitOrderContainer.Provider>
      ) : (
        []
      )}
      {itemToDelete !== null && (
        <ConfirmActionModal
          cancelAction={() => setItemToDelete(null)}
          confirmAction={onDeleteConfirmed}
          title="Delete Bait Order?"
          message="Do you want to delete this bait order?"
          confirmText="Delete"
        />
      )}
    </div>
  )
}

export default BaitOrders
