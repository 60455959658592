import { Dict } from '@memberapp/models'
import { LocationType } from '../models'
import { buildQueryParams, Get, Post, Put, RequestMethod, untypedRequest } from './httpSvc'

export const queryKey = 'locations'
const url = 'locations'

export const getLocations = async (query?: Dict<unknown>) => Get<LocationType[]>(buildQueryParams(url, query))

export const addLocation = async (obj: LocationType) => Post<LocationType>(url, obj)

export const editLocation = async (obj: LocationType) => Put<LocationType>(`${url}/${obj._id}`, obj)

export const deleteLocation = async (id: string) => untypedRequest(RequestMethod.DELETE, `${url}/${id}`)

export const enableLocation = async (id: string) =>
  untypedRequest(RequestMethod.PUT, `${url}/${id}`, { deletedAt: null })

type LandingLocationScheduleItem = {
  offloadTime: string
  boatName: string
  orderKey: number
}

export const getLandingSchedules = async (date: Date) =>
  Get<Dict<LandingLocationScheduleItem[]>>(`${url}/schedule?start=${date.toISOString()}`)
